import {Avatar, Popover } from 'antd'
import Image from 'components/image';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETUSERDETAILS, USERLOGOUT } from 'state/actions';

function UserMenu() {

    const dispatch = useDispatch();
    const { userDetails } = useSelector(state => state.user)

    const logoutUser = () => {
        dispatch(USERLOGOUT());
        // navigate("/")
    }

    useEffect(() => {
        dispatch(GETUSERDETAILS());
    },[])

    const content = (
        <div className='min-w-4 font-secondary'>
            <div className='p-3 -m-2 cursor-pointer'>Profile</div>
            <div  className='p-3 -m-2 cursor-pointer' onClick={logoutUser}>Logout</div>
        </div>
    );
    return (
        <div className='px-3 py-2 bg-tertiary rounded-full cursor-pointer'>
            <Popover placement="bottomRight" title={""} content={content} trigger="click">
                <div className='flex justify-center items-center space-x-2'>
                    {/* <Image url="profile.png" alt="profile"/> */}
                    <Avatar size="md" shape="circle" src={"https://avatar.iran.liara.run/public/boy?username="+ (userDetails.first_name || "User")} />
                    {/* <Avatar size="md" shape="circle" src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" /> */}
                    <div className='text-primary text-sm '>{userDetails.first_name || "User"} {userDetails.last_name || (userDetails?.uid)?.substring(1, 8) }</div>
                </div>
            </Popover>
        </div>
    )
}

export default UserMenu
