import React, { useState } from "react";
import '../styles/layout.css';
import Image from "components/image";

const PrePages = ({ children }) => {

    return (
        <div className="prepage-container w-full flex h-[100vh]">
            <div className="w-1/2 left-container-theme bg-gray-900 dark:bg-gray-900 flex flex-col justify-evenly ">
                <div className="flex flex-col text-center items-center space-y-5">
                    <div className="text-5xl text-white font-heading text-primary font-bold mb-2">
                        {/* Welcome to  */}
                         Dekko
                        {/* <Image url="dekko-logo.svg" alt="main-logo" /> */}
                    </div>
                    <div className="text-lg text-white font-normal">Discover Insights into People, Products, and <br/> Experiences</div>
                </div>            
                {/* <div className="flex justify-center items-center">
                    <Image url={"pre-login-page.png"} alt="Dekko App" className="w-[90%]" />
                </div>             */}
            </div>
            <div className="w-1/2 flex items-center justify-center">
                {children}
            </div>

        </div>
    );
};

export default PrePages;
