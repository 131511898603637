import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

function GaugeChart(props) {

  const [ series, setSeries] = useState([]);
  const options ={
    chart: {
      width: props.width,
      type: 'donut',

    },
    labels: [ "Approved", "Redo", "In-Review", "Awaiting Response", ""],
    colors: ['#12A58C', '#F6285F', '#FFD234' ,"#5a5a5a", '#343839' ],
    stroke: {
      show: true,
      colors: "var(--bg-secondary)",
      width: 2,
    },
    dataLabels: {
      enabled: false
    },
    // responsive: [{
    //   breakpoint: 480,
    //   options: {
    //     chart: {
    //       width: 200
    //     },
    //     legend: {
    //       show: false
    //     }
    //   }
    // }],
    legend: {
      show: false
    },
    plotOptions: {
      pie: {
        donut: {
          size: '75%',
          // labels: {
          //   show: true,
          //   name: {
          //     show: true,
          //     label: 'Total',
          //     fontSize: '22px',
          //     fontFamily: 'Helvetica, Arial, sans-serif',
          //     fontWeight: 600,
          //     color: undefined,
          //     offsetY: -10,
          //     formatter: function (val) {
          //       return val
          //     }
          //   },
          // }
        }
      }
    }
  };

  useEffect(() => {
    if (props?.data.length) {
      if ((props?.data[0] + props?.data[1] + props?.data[2] + props?.data[3]) > 0) {
        setSeries(props.data);
      } else {
        setSeries([0,0,0,0,100])
      }
    }
  },[props.data])

  return (
    <div class="chart-wrap relative">
      <div id="chart">
        <ReactApexChart options={options} series={series} type="donut" width={props.width} />
      </div>
      <div className={`absolute top-[30%] w-full px-[20%] text-center`}>
        {/* {(series.length && (series[1]+series[2]+series[3] > 0) ) ? props.label : <div className='text-sm text-secondary mt-2'>Progress <br/> Analysis</div>} */}
        {(series.length && (series[0]+series[1]+series[2] > 0) ) ? props.label : <div className='text-sm text-secondary mt-2'>Progress <br/> Analysis</div>}
      </div>
    </div>
  )
}

export default GaugeChart;


