import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, HourglassOutlined, TrademarkCircleOutlined } from '@ant-design/icons';
import { Pagination, Popover } from 'antd';
import Image from 'components/image';
// import CustomTable from 'components/Table';
// import Toolbar from 'components/Toolbar';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { GETPROJECTTASKPROGRESSREQUEST, GETPROJECTTASKS } from 'state/actions';

function ProgressGrid() {

    const columnTask = [
        {
            title: 'All Respondents',
            dataIndex: 'full_name',
            width: "600px",
        },
    ];

    const PopoverInfo = (status) => {
        return (
            <div className=''>
            <p>{status}</p>
          </div>
        )
    }

    const getStatusBlock = (status, userData, taskData) => {
        const userDataInfo = (<div className='bg-primary mx-[-12px] mt-[-12px] p-3 rounded-lg'><div className='text-base'>{taskData.title}</div><div className='text-xs'>By {userData.full_name}</div></div>)
        const statusBlock = {
            'None': <Popover content={PopoverInfo('Task not Assigned')} title={userDataInfo}><div className='flex items-center border-secondary border-t-4 w-6 -mx-4 px-4'></div></Popover>,
            'scheduled': <Popover content={PopoverInfo('Task is Scheduled')} title={userDataInfo}><div className='flex items-center'><ClockCircleOutlined className='!text-secondary' /></div></Popover>,
            'incomplete': <Popover content={PopoverInfo('Task not yet Started')} title={userDataInfo}><div className='flex items-center'><Image url="task-status-not-started.png" alt="profile" /></div></Popover>,
            'in_progress': <Popover content={PopoverInfo('Task is in Progress')} title={userDataInfo}><div className='flex items-center justify-center w-full h-full bg-[#D0EDE8]'><HourglassOutlined className='!text-neutral-800' /> </div></Popover>,
            'approved': <Popover content={PopoverInfo('Task Approved')} title={userDataInfo}><div className='flex items-center justify-center w-full h-full bg-[#12A58C]'><CheckCircleOutlined /></div></Popover>,
            'redo': <Popover content={PopoverInfo('Resubmit the response')} title={userDataInfo}><div className='flex items-center justify-center w-full h-full bg-[#923a34]'><CloseCircleOutlined /></div></Popover>,
            'in_review': <Popover content={PopoverInfo('Task under review')} title={userDataInfo}><div className='flex items-center justify-center w-full h-full bg-[#d7c84a]'><TrademarkCircleOutlined className='!text-neutral-800' /></div></Popover>,
        }

        return statusBlock[status]

    }

    const dispatch = useDispatch();
    const { projectRespondents, tasks, taskProgressUsers } = useSelector(state => state.task);
    const { selProject } = useSelector(state => state.user)
    const [userData, setUserData] = useState([]);
    // const [columnSet, setColumnSet] = useState(columnTask);
    const [current, setCurrent] = useState(1);


    useEffect(() => {
        if (taskProgressUsers?.respondents) {
            setUserData(taskProgressUsers.respondents)
        }
        setCurrent(taskProgressUsers?.page)
    }, [taskProgressUsers])

    useEffect(() => {
        if (selProject?.uuid) {
            dispatch(GETPROJECTTASKPROGRESSREQUEST({ project_uuid: selProject.uuid, page: 1, pageSize: 10 }))
            dispatch(GETPROJECTTASKS(selProject.uuid))
        }
    }, [selProject])

    // useEffect(() => {
    //     if (tasks.length) {

    //         const columnTask = [
    //             {
    //                 title: 'All Respondents',
    //                 dataIndex: 'full_name',
    //                 width: "600px",
    //             }
    //         ];


    //         tasks.map(t => {
    //             let tempTask = {
    //                 title: <div className='abc' >{t.title}</div>,
    //                 dataIndex: t.uuid,
    //                 width: "100px"
    //             }
    //             columnTask.push(tempTask)
    //         })

    //         setColumnSet(columnTask);


    //         // setUserData();

    //     }

    // }, [tasks, taskProgressUsers])

    const checkIfTaskExist = (userData, taskId) => {

        let returnStatus = "None";

        userData.tasks.map(ut => {
            if (ut.task_uuid == taskId.uuid) {
                returnStatus = ut.status
            }
        })
        return getStatusBlock(returnStatus, userData, taskId)
    }

    const onChange = (page) => {
        dispatch(GETPROJECTTASKPROGRESSREQUEST({ project_uuid: selProject.uuid, page: page, pageSize: 10 }))
        setCurrent(page);
    };

    return (
        <React.Fragment>
            {/* <Toolbar isStatus /> */}
            <div className='pt-4'>
                <div className='flex font-light w-full text-left bg-secondary rounded-t-md items-stretch'>
                    <div className='w-[575px] px-2 py-3 font-light text-base border-r border-secondary'>
                        Respondents
                    </div>
                    {
                        tasks.map(t => (
                            <div className='px-2 py-3 w-[150px] font-light text-base  border-r border-secondary relative text-center'>
                                <span className=''>{t.title}</span>
                            </div>
                        ))
                    }
                </div>
                <div className='flex flex-col font-light w-full text-left bg-primary rounded-lg items-end'>
                    {
                        userData.length ?
                            <React.Fragment>
                                {
                                    userData.map(u => (
                                        <div className='border-b flex w-full border-secondary border-r'>
                                            <div className='w-[575px] font-light text-base border-r border-l border-secondary py-4 px-2'>
                                                {u.full_name}
                                            </div>
                                            {
                                                tasks.map(t => (
                                                    <div className='w-[150px] font-light text-base  border-r  border-secondary flex items-center justify-center'>
                                                        {checkIfTaskExist(u, t)}
                                                    </div>
                                                ))
                                            }

                                        </div>
                                    ))
                                }
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div className='border-b flex w-full border-secondary border-r border-l p-2 justify-center'>
                                   <div className='text-base text-secondary'>No Respondents Found</div> 
                                </div>
                            </React.Fragment>
                    }
                </div>
            </div>
            <div className='pt-3'>
                <Pagination align="end" current={current} onChange={onChange} total={50} />
                {/* <Pagination align="end" current={current} onChange={onChange} total={taskProgressUsers?.totalCount} /> */}
            </div>
        </React.Fragment>
    )

}

export default ProgressGrid
