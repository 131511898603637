import { all, put, take, select, takeEvery } from "redux-saga/effects";
import { createProjectTaskRequest, deleteProjectTaskRequest, getClientProjectRequest, getOverviewRequest, getProjectOverviewRequest, getProjectRespondentsRequest, getProjectTasksRequest, getUserDetailsRequest, loginByMobileRequest, updateTaskRequest, userLogoutRequest, verifyOTPMobileRequest, getsubtasksdetailsRequest, assigntaskstorespondentRequest, getallrespondentsprogressRequest } from "services/api";
import { ASSIGNTASKSUCCESS, DELETEPROJECTTASKSUCCESS, REMOVEUSERAUTH, RESETMOBILEOTP, SETCLIENTPROJECTS, SETLOADING, SETMOBILEOTP, SETNOTIFICATION, SETOVERVIEW, SETPROJECTOVERVIEW, SETPROJECTRESPONDENTS, SETPROJECTTASKPROGRESS, SETPROJECTTASKS, SETTASKDETAILS, SETUSERAUTH, SETUSERDETAILS, TASKADDEDSUCCESS, UPDATETASKSUCCESS } from "./actions";



function* watchAndLog() {
    while (true) {
        const action = yield take("*");
        const state = yield select();
        console.log("Action:", action, "State:", state);
        if (!action || !state) break;
    }
}


function* loginByMobile(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield loginByMobileRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETMOBILEOTP(response.data?.response?.data));
            } else {
                yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        yield put(SETLOADING(false));
        yield put(SETNOTIFICATION({ message: e?.message, type: "error" }));
    }
}


function* verifyMobileOTP(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield verifyOTPMobileRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                // console.log("response.data?.response", response.data?.response);
                yield put(SETUSERAUTH(response.data?.response?.data));
            } else {
                yield put(SETNOTIFICATION({ message: "Invalid OTP", type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e-->", e);
        yield put(SETLOADING(false));
        yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message, type: "error" }));
    }
}

function* userLogout(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield userLogoutRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(REMOVEUSERAUTH(response.data?.response?.data));
                yield put(RESETMOBILEOTP([]));
            } else {
                yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        yield put(SETLOADING(false));
        yield put(SETNOTIFICATION({ message: e?.message, type: "error" }));
    }
}

function* getUserDetails(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield getUserDetailsRequest();
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                // yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETUSERDETAILS(response.data?.response?.data));
            } else {
                yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* getClientProjects(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield getClientProjectRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                // yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETCLIENTPROJECTS(response.data?.response?.data));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* getProjectOverview(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield getProjectOverviewRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                // yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETPROJECTOVERVIEW(response.data?.response?.data));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        yield put(SETPROJECTOVERVIEW([]));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* getOverview(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield getOverviewRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                // yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETOVERVIEW(response.data?.response?.data));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* getsubtasksdetailsbyuuid(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield getsubtasksdetailsRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                // yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETTASKDETAILS(response.data?.response?.data));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* getProjectRespondents(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield getProjectRespondentsRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                // yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETPROJECTRESPONDENTS(response.data?.response?.data));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* getallrespondentsprogress(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield getallrespondentsprogressRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                // yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETPROJECTTASKPROGRESS(response.data?.response?.data));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* updateTask(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield updateTaskRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                yield put(SETNOTIFICATION({ message: "Task updated", type: "success" }));
                yield put(UPDATETASKSUCCESS(response.data?.response?.status));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* getProjectTasks(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield getProjectTasksRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                // yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(SETPROJECTTASKS(response.data?.response?.data));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* createProjectTask(payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield createProjectTaskRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(TASKADDEDSUCCESS(response.data?.response?.status));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* deleteProjectTask (payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield deleteProjectTaskRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(DELETEPROJECTTASKSUCCESS(response.data?.response?.status));
            } else {
                // yield put(SETNOTIFICATION({ message: response.data?.message, type: "error" }));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

function* assignTask (payload) {
    try {
        yield put(SETNOTIFICATION({ message: "", type: "" }));
        const response = yield assigntaskstorespondentRequest(payload);
        if (response.data.statusCode != 200) {
            throw(response.data.response)
        }
        if (response.data?.response) {
            if (response.data?.response?.code === 200) {
                yield put(SETNOTIFICATION({ message: response.data?.response?.message, type: "success" }));
                yield put(ASSIGNTASKSUCCESS(true));
            }
        }
        yield put(SETLOADING(false));
    } catch (e) {
        console.log("e)", e);
        yield put(SETLOADING(false));
        // yield put(SETNOTIFICATION({ message: e?.message || e?.response?.message , type: "error" }));
    }
}

export default function* rootSaga() {
    yield all([
        watchAndLog(),
        takeEvery("LOGIN_BY_MOBILE_REQUEST", loginByMobile),
        takeEvery("VERIFY_MOBILE_OTP_REQUEST", verifyMobileOTP),
        takeEvery("USER_LOGOUT", userLogout),
        takeEvery("GET_USER_DETAILS_REQUEST", getUserDetails),
        takeEvery("GET_CLIENT_PROJECT_REQUEST", getClientProjects),
        takeEvery("CREATE_PROJECT_TASK_REQUEST", createProjectTask),
        takeEvery("GET_PROJECT_TASKS_REQUEST", getProjectTasks),
        takeEvery("GET_PROJECT_TASK_PROGRESS_REQUEST", getallrespondentsprogress),
        takeEvery("DELETE_PROJECT_TASKS_REQUEST", deleteProjectTask),
        takeEvery("GET_PROJECT_OVERVIEW_REQUEST", getProjectOverview),
        takeEvery("GET_OVERVIEW_REQUEST", getOverview),
        takeEvery("GET_PROJECT_RESPONDENTS_REQUEST", getProjectRespondents),
        takeEvery("UPDATE_TASK_REQUEST", updateTask),
        takeEvery("GET_TASK_DETAILS_REQUEST", getsubtasksdetailsbyuuid),
        takeEvery("ASSIGN_TASK_REQUEST", assignTask),
    ]);
}
