import React, { useEffect, useState } from 'react';
import { Divider, Radio, Table } from 'antd';
const columns = [
  {
    title: 'Name',
    dataIndex: 'full_name',
    render: (text) => <a>{text}</a>,
  },
  {
    title: 'Initials',
    dataIndex: 'initials',
  },
  {
    title: 'UUID',
    dataIndex: 'respondent_uuid',
  },
];

const CustomTable = (props) => {


  const [rowData, setRowData] = useState([]);

  const [select, setSelect] = useState({
    selectedRowKeys: [],
    selectedRows: [],
    loading: false,
  });

const { selectedRowKeys, loading } = select;

useEffect(() => {
  if (props?.tableData.length) {

    const rowDataTemp =  props?.tableData.map((data, i) => ({
      key: i,
      ...data
    }));
    setRowData(rowDataTemp);
  }
},[props?.tableData])

const rowSelection = {
  selectedRowKeys,
  selections : [
    Table.SELECTION_ALL,
    Table.SELECTION_INVERT,
    Table.SELECTION_NONE
  ],
  onChange: (selectedRowKeys, selections, selectedRows) => {

    props.onSelect(selectedRows, selections)
    // console.log("selectedRows-->", selectedRows);
    // console.log("selections -->", selections);
    // console.log("selectedRowKeys-->", selectedRowKeys);
    setSelect({
      ...select,
      selectedRowKeys: selectedRowKeys,
      selectedRows: selectedRows
    });
  }
};

  return (
    <div className={'pb-4 text-primary ' + props.className}>
      <Table
        rowSelection={rowSelection}
        columns={props?.columns ? props.columns : columns}
        dataSource={rowData}
        loading={loading}
      />
    </div>
  );
};
export default CustomTable;