import { AppstoreOutlined, ClockCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import constants from 'utils/constants';
import helper from 'utils/helper';
import CustomModalPopUp from 'components/Modal/customModal';
import ViewResponseModal from './viewResponseModal';
import GallaryMediaCard from 'components/image/GallaryMediaCard';
import { Avatar, Tooltip } from 'antd';

function Collection({ details = {}, submitTask = {} }) {
  // const [ collectionDetails, setCollectionDetails ] = useState({});
  const { selProject } = useSelector(state => state.user)
  const [showDetails, setShowDetails] = useState(false);
  // const { overviewResponse, taskUpdated, projectRespondents } = useSelector(state => state.task)



  return (
    <React.Fragment>
      <div className='bg-secondary relative rounded-xl p-3 first:ml-0 max-h-max tile-card w-full' onClick={() => setShowDetails(true)} >
      {/* <div className='bg-secondary relative rounded-xl p-3 ml-3 first:ml-0 max-h-max tile-card w-full' onClick={() => setShowDetails(true)} > */}
        {
          !helper.isObjectEmpty(details) && (
            <div className='flex flex-col items-start relative'>
              <div className='media-wrap min-h-24 w-full relative'>
                {/* <div className='absolute left-1 top-1'> */}
                {/* <div className='absolute right-1 bottom-1 z-10'> */}
                  {/* <div className='text-xs py-1 px-2 bg-primary bg-opacity-80 rounded-xl'>Recent</div> */}
                  {/* <div className='text-[10px] py-1 px-2 bg-primary opacity-60 rounded-xl'>Last update by {details?.respondent_name}</div> */}
                {/* </div> */}
                <div className='absolute right-1 bottom-0'>
                  <Avatar.Group
                    size="default"
                    max={{
                      count: 4,
                      style: { color: 'var(--font-primary)', backgroundColor: 'var(--bg-tertiary)', fontSize: '12px' },
                    }}
                  >
                    {
                      details?.respondent_name?.split(",").map((user, index) => (
                        <Tooltip title={user} placement="top">
                          <Avatar className='bg-secondary border-2 ' src={"https://api.dicebear.com/9.x/personas/svg?backgroundType=gradientLinear,solid&seed=" + user} />
                          {/* <Avatar className='bg-secondary border-2 ' src={"https://api.dicebear.com/7.x/miniavs/svg?seed=" + user} /> */}
                        </Tooltip>
                      ))
                    }
                  </Avatar.Group>
                </div>
                <GallaryMediaCard urls={details?.media_link} wrapperclassName="w-full rounded-lg bg-tertiary" className="!h-[270px] !w-[330px] !object-cover" project={selProject} details={details} noControl videoClass="h-[290px]" />
              </div>
              <div className=' flex justify-between w-full p-1 top-1'>
                <div className='flex items-center text-secondary text-base'>
                  <AppstoreOutlined style={{ color: 'text-[var(--color-secondary)]' }} />
                  <span className='pl-1'>Collection</span>
                </div>
                <div className='flex px-3 py-1 rounded-xl bg-secondary'>
                  <div className='font-secondary text-xs'>{moment(details.submitted_date).fromNow()}</div>
                </div>
              </div>
              <div className='px-1 py-2 flex justify-between w-full items-baseline'>
                <div className='flex flex-col text-left  space-y-2 w-full'>
                  <div className='flex items-center justify-between'>
                    <div className='font-secondary text-base capitalize w-[65%]'>{details.task_title}</div>
                    <div className='w-fit'>
                      {
                        details.submitted_task_status == "in_review" ?
                          <div className={`font-secondary text-xs bg-status px-2 py-[1px] rounded-xl text-[var(--font-review)]`}> <ClockCircleOutlined style={{ color: "text-[var(--font-review)" }} /> {constants.RESPONSE_STATUS[details.submitted_task_status]}</div>
                          : (details.submitted_task_status == "rejected" || details.submitted_task_status == "redo") ?
                            <div className={`font-secondary text-xs bg-status px-2 py-[1px] rounded-xl text-[var(--font-rejected)]`}> <ClockCircleOutlined style={{ color: "text-[var(--font-rejected)" }} /> {constants.RESPONSE_STATUS[details.submitted_task_status]}</div>
                            :
                            <div className={`font-secondary text-xs bg-status px-2 py-[1px] rounded-xl text-[var(--font-approved)]`}> <ClockCircleOutlined style={{ color: "text-[var(--font-approved)" }} /> {constants.RESPONSE_STATUS[details.submitted_task_status]}</div>
                      }
                    </div>
                  </div>
                  <div className='font-secondary text-secondary text-sm'>{details.task_description}</div>
                </div>
              </div>
            </div>
          )
        }
      </div>

      {showDetails &&
        <CustomModalPopUp
          // title={details?.task_title + "'s  responses"}
          title={details?.task_title}
          okText="Back"
          openPopup={showDetails}
          onCancel={() => setShowDetails(false)}
          width={'95%'}
        >
          <ViewResponseModal details={details} submitTask={submitTask} onCancel={() => setShowDetails(false)} />
        </CustomModalPopUp>
      }
    </React.Fragment>
  )
}

export default Collection
